var site = site || {};

site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue =
  site.userInfoCookie.getValue ||
  function () {
    return '';
  };
(function ($) {
  Drupal.behaviors.gnavFormatterV1 = {
    attach: function (context) {
      var self = this;
      var animationInProgress = false;
      var $modules = $('.js-gnav-formatter--v1', context);
      var $navParent = $('.gnav-menu-item--has-children, .gnav-menu-link--category-expandable', $modules);
      var $topNav = $('.js-gnav-formatter--lvl-1', $modules);
      var $navbar = $('.js-gnav-formatter-navbar', $modules);
      var $navbarTitle = $('.js-gnav-formatter-navbar-title', $navbar);
      var $goBack = $('.js-gnav-formatter-navbar-back', $navbar);
      var $allNavTouts = $('.js-gnav-menu-tout-v2', $topNav);
      var $navArrow = $('.js-gnav-menu-item__arrow-icon', $navParent);
      var $navClose = $('.js-gnav-formatter__close', $navParent);
      var mobilePaginationEnabled = Drupal.settings.navigation && Drupal.settings.navigation.is_2020_nav_redesign;
      // Loyalty variables
      var isLoyalty = +site.userInfoCookie.getValue('is_loyalty_member');
      var firstName = site.userInfoCookie.getValue('first_name');
      var currentPoint = site.userInfoCookie.getValue('available');
      var loyaltyLevel = +site.userInfoCookie.getValue('loyalty_level');
      var profilePicture = site.userInfoCookie.getValue('profile_picture');
      var timeout;

      if (Drupal.settings.navigation && Drupal.settings.navigation.is_cr20_desktop_nav_refresh) {
        $navParent.filter('.gnav-menu-item--has-children')
          .off('mouseleave.gnavFormatter mouseenter.gnavFormatter click.gnavFormatter')
          .on('mouseleave.gnavFormatter', function () {
            clearTimeout(timeout);
            $(this).removeClass('expanded');
          })
          .on('click.gnavFormatter', function () {
            var $this = $(this);

            $this.toggleClass('expanded', true);

            // Resize slick slider for sticky nav.
            setTimeout(function () {
              $('.slick-initialized', $this).slick('setPosition');
            }, 250);
          })
          .off('mouseenter.gnavFormatter')
          .on('mouseenter.gnavFormatter', function () {
            var $this = $(this);

            // Delay the opening of the Gnav menu.
            timeout = setTimeout(function () {
              $this.toggleClass('expanded', true);
            }, 200);

            // Resize slick slider for sticky nav.
            setTimeout(function () {
              $('.slick-initialized', $this).slick('setPosition');
            }, 250);
          });

        $navArrow.off('click.gnavFormatter').on('click.gnavFormatter', function () {
          var $parent = $(this).closest('.gnav-menu-item--has-children');

          $parent.siblings('.expanded').removeClass('expanded');
          $parent.toggleClass('expanded');
        });

        $navClose.off('click.closeGnav').on('click.closeGnav', function (e) {
          var $parent = $(this).closest('.gnav-menu-item--has-children');

          e.preventDefault();
          e.stopPropagation();
          $parent.removeClass('expanded');
        });

        $(window)
          .on('keydown.gnavFormatter', function (event) {
            var key = event.key;

            if (
              !$('html').hasClass('ada-enabled') &&
              ['Tab', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'].indexOf(key) > -1
            ) {
              $('html').addClass('ada-enabled');
            }
          })
          .on(
            'mousemove.gnavFormatter',
            _.throttle(function () {
              $('html').removeClass('ada-enabled');
            }, 200)
          );
      }

      $navParent.off('click.expand').on('click.expand', function (e) {
        var $self = $(this);

        if ($(e.target).closest('a').length === 0) {
          e.preventDefault();
          e.stopPropagation();
          if (!mobilePaginationEnabled) {
            $self.toggleClass('menu__item--selected');
          } else {
            runAnimationLoop($self);
          }
        }
      });

      // Give each tout an ID
      $allNavTouts.each(function (index) {
        $(this).attr('data-tout-index', index);
      });
      function renderNavbar() {
        var $selected = $('.menu__item--selected', $topNav);
        var title = '';

        if (!$selected.length) {
          $navbar.hide();

          return false;
        }
        $selected.each(function () {
          if (title.length) {
            title += '<span class="divider">' + $navbarTitle.data('divider') + '</span>';
          }
          if ($(this).text().trim()) {
            title += '<span class="selection">';
            title += $(this).find('> .gnav-menu-item__title, > .gnav-menu-label').text().trim();
            title += '</span>';
          }
        });
        $navbarTitle.html(title);
        $navbar.show();
      }

      // eslint-disable-next-line complexity
      function renderTout() {
        var $lastOpenMenu = $topNav
          .parent()
          .find('.gnav-formatter__list--selected')
          .last()
          .find('.js-gnav-formatter__list')
          .first();
        var $topOpenList = $topNav.parent().find('.js-gnav-formatter__list').first();
        var $activeTout = $('body').children('.js-gnav-menu-tout-v2');
        // This needs to be dynamic
        var $menuTout = (function () {
          if ($lastOpenMenu.children('.js-gnav-menu-tout-v2').length) {
            return $lastOpenMenu.children('.js-gnav-menu-tout-v2');
          } else if ($lastOpenMenu.children('.gnav-menu-item__content, .menu__item--selected').length) {
            return $lastOpenMenu
              .children('.gnav-menu-item__content, .menu__item--selected')
              .find('.js-gnav-formatter__list')
              .first()
              .children('.js-gnav-menu-tout-v2');
          }

          return $();
        })();
        var menuWithToutOpen = $lastOpenMenu.length || $menuTout.length;

        $topOpenList.toggleClass('gnav-formatter__list--closed', !menuWithToutOpen);
        if (!menuWithToutOpen) {
          if ($menuTout && $menuTout.length) {
            $activeTout.remove();
            $lastOpenMenu.parent().css({ 'padding-bottom': '' });
          }
        }
        if ($menuTout.length && $activeTout.attr('data-tout-index') !== $menuTout.attr('data-tout-index')) {
          $activeTout.remove();
          $('body').append($menuTout.clone().addClass('active'));
          if (mobilePaginationEnabled) {
            $lastOpenMenu.parent().css({
              'padding-bottom': $menuTout.height()
            });
          }
        } else {
          if (!$lastOpenMenu.length) {
            $activeTout.remove();
            $topOpenList.css({ 'padding-bottom': '' });
          }
        }
      }

      function applyAnimationState(childMenusWillExpand) {
        $topNav.toggleClass('animation-active');
        if ($topNav.hasClass('animation-active')) {
          if (childMenusWillExpand) {
            $topNav.addClass('gnav-formatter__list-animation--start');
          } else {
            $topNav.addClass('gnav-formatter__list-animation--start-reverse');
          }
        } else {
          if (childMenusWillExpand) {
            $topNav.addClass('gnav-formatter__list-animation--end');
          } else {
            $topNav.addClass('gnav-formatter__list-animation--end-reverse');
          }
        }
      }

      function clearAnimationStateClasses() {
        $topNav.removeClass(
          'gnav-formatter__list-animation--start gnav-formatter__list-animation--start-reverse gnav-formatter__list-animation--end gnav-formatter__list-animation--end-reverse'
        );
      }

      function setAnimationInProgress(enable) {
        animationInProgress = enable;
        if (!enable) {
          $topNav.off('animationend.first animationend.second');
        }
      }

      $goBack.off('click.back').on('click.back', function () {
        if (animationInProgress) {
          return false;
        }
        $topNav.find('.menu__item--selected').last().trigger('click');
      });

      $('.js-gnav-formatter-navbar-account-dropdown', $modules)
        .off('click.dropdown')
        .on('click.dropdown', function () {
          var $self = $(this);

          $self.toggleClass('active');
          $topNav.css({
            visibility: $self.hasClass('active') ? 'hidden' : 'visible'
          });
          $navbar.css({
            visibility: $self.hasClass('active') ? 'hidden' : 'visible'
          });
          $('.js-gnav-util-account-menu', $modules).toggleClass('hidden');
        });

      function runAnimationLoop($element) {
        var childMenusWillExpand = $element.hasClass('menu__item--selected') === false;

        if (animationInProgress) {
          return false;
        }
        setAnimationInProgress(true);
        applyAnimationState(childMenusWillExpand);
        $(window)
          .off('resize.nav')
          .on(
            'resize.nav',
            _.debounce(function () {
              renderTout();
            }, 100)
          );
        $topNav.off('animationend.first').one('animationend.first', function () {
          if (!animationInProgress) {
            return false;
          }
          $element.toggleClass('menu__item--selected');
          $element.closest('.js-basic-menu-ref-v2').toggleClass('active');
          if (childMenusWillExpand) {
            $element.parents('.js-gnav-formatter-lvl').addClass('gnav-formatter__list--selected');
          } else {
            $topNav.parent().find('.gnav-formatter__list--selected').removeClass('gnav-formatter__list--selected');
            $topNav
              .find('.menu__item--selected')
              .parents('.js-gnav-formatter-lvl')
              .addClass('gnav-formatter__list--selected');
            if (!$topNav.find('.menu__item--selected').length) {
              $topNav.removeClass('gnav-formatter__list--selected');
            }
          }
          renderNavbar();
          renderTout();
          setTimeout(function () {
            applyAnimationState(childMenusWillExpand);
          }, 100);
          $topNav.off('animationend.second').one('animationend.second', function () {
            if (!animationInProgress) {
              return false;
            }
            clearAnimationStateClasses();
            setAnimationInProgress(false);
          });
        });
      }

      $('.gnav-formatter--lvl-3', context).each(function () {
        $(this)
          .find('.gnav-menu-link:not(.gnav-menu-link--category-expandable)')
          .wrapAll('<div class="gnav-menu-link-group">');
      });
      Unison.on('change', function () {
        self.moveSearch();
      });

      $(document).on('gnav-util.close', function () {
        clearAnimationStateClasses();
        setAnimationInProgress(false);
        $modules.find('.gnav-formatter__list--selected').removeClass('gnav-formatter__list--selected');
        $modules.find('.menu__item--selected').removeClass('menu__item--selected');
        $modules.find('.gnav-menu-item, .basic-menu-ref').removeClass('active hidden');
        renderNavbar();
        renderTout();
      });
      self.moveSearch();
      renderTout();
    },

    moveSearch: function () {
      var bps = Unison.fetch.all();
      var bp = Unison.fetch.now();
      var isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
      var $search = $('.gnav-util--groupby-search').remove();
      var search_location = isDesktop ? 'pc' : 'mobile';

      $('.search-wrapper--' + search_location).append($search);
    },
    isMobile: function () {
      return +$.cookie('client.isMobile') || 0;
    }
  };
})(jQuery);
